import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import constants from './constants';

import Image from '../src/assets/hero_bg.jpg';

const generateTitle = title => {
  let pageTitle = '';

  if (typeof title === 'undefined') {
    pageTitle =
      'Jorisan Waterfront Resort — Beach Resort, Hotel and Restaurant in Bauang, La Union';
  } else {
    pageTitle = `${title} — Jorisan Waterfront Resort`;
  }

  return pageTitle;
};

const helmet = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content={constants.description} />

    <title>{generateTitle(title)}</title>

    <meta property="og:url" content={constants.url} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={constants.title} />
    <meta property="og:description" content={constants.description} />
    <meta property="og:image" content={Image} />
    <meta property="og:image:alt" content={Image} />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={constants.title} />
    <meta name="twitter:description" content={constants.description} />
    <meta name="twitter:image" content={Image} />
  </Helmet>
);

helmet.propTypes = {
  title: PropTypes.string.isRequired,
};

export default helmet;
